const eng = {
    ////////////////////////////   Header    ///////////////////////////////

    Home: 'HOME',
    About: 'ABOUT',
    Services: 'SERVICES',
    Experiences: 'EXPERIENCES',
    Projects: 'PROJECTS',
    Contact: 'CONTACT',

    ////////////////////////////   Home    ///////////////////////////////

    Hi: 'Hi, I am',
    // QuickDesc: "and I'm a skilled web developer on a mission to turn innovative ideas into engaging online realities.",
    QuickDesc: "and I'm a web developer with a passion for crafting dynamic online experiences and bringing innovative ideas to life.",
    ContactMe: 'Contact Me',
    CV: 'Resume',

    ////////////////////////////   About    ///////////////////////////////

    AboutTitle: 'About Me',
    AboutSubTitle: 'Know Me More',
    AboutBody:
        'I’m a passionate software developer with over 7 years of experience in web technologies. I take pride in crafting clean, user-friendly websites that deliver real value to users. My commitment to meeting client requirements ensures that I deliver projects on time and within budget. Each line of code I write transforms concepts into dynamic web experiences, contributing to a digital landscape that is both intuitive and engaging.\n\n' +
        'Building on this foundation, I have also gained significant experience as a DevOps engineer, enabling me to streamline development and operations for even greater efficiency and collaboration.',

    AboutYears: 'Years of ',

    /**
     * 
        // "I'm an avid developer deeply passionate about web technologies. I enjoy crafting simple, clean and slick websites that provide real value to the end user. Delivering work within time and budget which meets client’s requirements is our moto. With every keystroke, I'm dedicated to transforming concepts into dynamic web experiences, contributing to a digital realm that's both intuitive and engaging.",
     * 
     */
    ////////////////////////////   Services    ///////////////////////////////

    ServicesTitle: 'What i do?',
    ServicesSubTitle: 'My Technologies Stack',

    ////////////////////////////   Experience    ///////////////////////////////

    ExperienceTitle: 'What i have done so far',
    ExperienceSubTitle: 'Work Experience',

    ////////////////////////////   Projects    ///////////////////////////////

    ProjectsTitle: 'My Works',
    ProjectsSubTitle: 'Projects',

    ////////////////////////////   Contact    ///////////////////////////////

    InTouch: "Let's Get in Touch",
    InTouchDesc:
        // 'I enjoy discussing new projects and design challenges. Please share as much info as possible so we can get the most out of our first catch-up.',
        'I’d love to discuss new projects and design challenges. Share any details you can so we make the most of our first chat.',
    Living: 'Living In',
    Call: 'Call',

    Name: 'Your Name*',
    Email: 'Your Email*',
    Message: 'Your Message*',
    Send: 'Send',
    Sent: 'Your Message has been sent'
};
export default eng;
