import React, { useRef, useState } from 'react';
import { Grid, Typography, TextField, Button, Divider, Stack, Box, TextareaAutosize, InputLabel, Alert } from '@mui/material';
import { ColoredTheme, config } from '../../config';
import { ArrowForward } from '@mui/icons-material';
import SlideUp from '../Animations/SlideUp';
import { useSelector } from 'react-redux';
import eng from '../../Data/eng';
// import fr from '../../Data/fr';
import emailjs from '@emailjs/browser';
import SlideLeft from '../Animations/SlideLeft';

const Contact = () => {
    const { value } = useSelector((state) => state.lang);
    const data = value === 'en' ? eng : eng;
    const form = useRef();
    const [isSent, setIsSent] = useState(false);

    // State to hold form values and error messages
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [errors, setErrors] = useState({
        name: '',
        email: '',
        message: ''
    });

    // Update form values on change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    // Validate fields and check for any empty values
    const validate = () => {
        const newErrors = {
            name: formData.name ? '' : 'Name is required.',
            email: formData.email ? '' : 'Email is required.',
            message: formData.message ? '' : 'Message is required.'
        };
        setErrors(newErrors);
        return !newErrors.name && !newErrors.email && !newErrors.message;
    };
    const sendEmail = (e) => {
        e.preventDefault();

        // Only send if validation passes
        if (validate()) {
            emailjs
                .sendForm('service_5ktuw7d', 'template_l0whvto', form.current, {
                    publicKey: 'RQKwnxDojdbp4v5Jc'
                }) // Replace '1234' with your actual public key
                .then(
                    () => {
                        setIsSent(true);
                    },
                    (error) => {
                        console.error('FAILED...', error.text);
                    }
                );
            form.current.reset();
            setFormData({ name: '', email: '', message: '' });
            setErrors({ name: '', email: '', message: '' });
        }
    };
    // const sendEmail = (e) => {
    //     e.preventDefault();
    //     emailjs
    //         .sendForm('service_5ktuw7d', 'template_l0whvto', form.current, {
    //             publicKey: 'RQKwnxDojdbp4v5Jc'
    //         })
    //         .then(
    //             () => {
    //                 setIsSent((current) => !current);
    //             },
    //             (error) => {
    //                 console.log('FAILED...', error.text);
    //             }
    //         );
    //     form.current.reset();
    // };
    return (
        <Box
            component="section"
            id="Contact"
            sx={{
                // backgroundColor: ColoredTheme ? config.PrimaryColor : 'unset',
                color: config.TextColor,
                // minHeight: '100vh',
                overflow: 'hidden',
                padding: '6.5vh 0 6vh 0'
            }}
        >
            <Stack justifyContent="start" alignItems="center">
                <Typography variant="subtitle1" sx={{ backgroundColor: config.PrimaryColor, paddingX: '10px', fontWeight: '500' }}>
                    Contact
                </Typography>
            </Stack>
            <SlideUp>
                <Stack
                    mt={6}
                    direction={{
                        xs: 'column',
                        md: 'row'
                    }}
                    spacing={{ xs: 6 }}
                    justifyContent="space-evenly"
                    alignItems={{ xs: 'center', md: 'flex-start' }}
                    flexWrap="wrap"
                >
                    <Stack justifyContent="space-between" spacing={2} width={{ xs: '80%', md: '25%', lg: '25%' }}>
                        <Typography variant="h4" sx={{ fontWeight: '600', borderBottom: '4px solid #f5df4e', paddingBottom: '5px' }}>
                            {data.InTouch}
                        </Typography>
                        <Typography variant="body1" fontWeight="normal" sx={{ textIndent: 20 }}>
                            {data.InTouchDesc}
                        </Typography>
                        <Typography variant="h5" fontWeight="500">
                            {data.Living}
                        </Typography>
                        <Typography variant="body1">Hyderbad, IND.</Typography>
                        <Typography variant="h5" fontWeight="500">
                            Email:
                        </Typography>
                        <Typography variant="body1">ravi.rsinghvaishali@gmail.com</Typography>
                        <Typography variant="h5" fontWeight="500">
                            {data.Call}
                        </Typography>
                        <Typography variant="body1">+91 93088 74004</Typography>
                    </Stack>
                    <Stack
                        width={{ xs: '80%', md: '25%', lg: '25%' }}
                        sx={{ ms: 'auto', mt: 5, mtLg: 0, wow: 'fadeInUp', visibility: 'visible', animationDelay: '0.3s' }}
                    >
                        <Typography variant="h4" sx={{ fontWeight: '600', borderBottom: '4px solid #f5df4e', paddingBottom: '5px' }}>
                            {data.ContactMe}
                        </Typography>
                        <Box component="form" ref={form} onSubmit={sendEmail} sx={{ marginBottom: '4rem' }} mt={4}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <InputLabel htmlFor="name">{data.Name}:</InputLabel>
                                    {/* <TextField id="name" name="name" fullWidth variant="standard" /> */}
                                    <TextField
                                        id="name"
                                        name="name"
                                        fullWidth
                                        variant="standard"
                                        value={formData.name}
                                        onChange={handleChange}
                                        error={!!errors.name}
                                        helperText={errors.name}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel htmlFor="email">{data.Email}:</InputLabel>
                                    {/* <TextField id="email" name="email" fullWidth variant="standard" /> */}
                                    <TextField
                                        id="email"
                                        name="email"
                                        fullWidth
                                        variant="standard"
                                        value={formData.email}
                                        onChange={handleChange}
                                        error={!!errors.email}
                                        helperText={errors.email}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel htmlFor="message">{data.Message}:</InputLabel>
                                    {/* <TextField id="message" name="message" fullWidth variant="standard" multiline rows={5} /> */}
                                    <TextField
                                        id="message"
                                        name="message"
                                        fullWidth
                                        variant="standard"
                                        multiline
                                        rows={5}
                                        value={formData.message}
                                        onChange={handleChange}
                                        error={!!errors.message}
                                        helperText={errors.message}
                                    />
                                </Grid>
                                {isSent && (
                                    <Grid item xs={12}>
                                        <SlideLeft>
                                            <Alert severity="success">{data.Sent}</Alert>
                                        </SlideLeft>
                                    </Grid>
                                )}
                                <Grid item xs={12} text-lg-start>
                                    <Button
                                        id="submit-btn"
                                        variant="contained"
                                        type="submit"
                                        sx={{
                                            borderRadius: 0,
                                            backgroundColor: 'black',
                                            color: 'white',
                                            '&:hover': {
                                                backgroundColor: '#424649',
                                                boxShadow: '0 5px 15px rgba(0,0,0,.3)',
                                                transition: '0.4s'
                                            }
                                        }}
                                        endIcon={<ArrowForward />}
                                    >
                                        {data.Send}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Stack>
                </Stack>
            </SlideUp>
        </Box>
    );
};

export default Contact;
