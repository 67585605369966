import ppl from '../../assets/images/peoplelink.jpg';
import instavc from '../../assets/images/instavc.png';

const Experience = [
    {
        icon: instavc,
        date: 'April 2023 - Present',
        title: 'Analyst-Software Engineer @InstaVC Technologies Private Limited',
        subtitle: 'Hyderabad-Telangana, India',
        body: [
            'Developed and maintained responsive web applications using ReactJS, TypeScript, and JavaScript',
            'Implemented code-splitting techniques to enhance application performance.',
            'Actively engaged in Agile methodologies, including sprint planning, daily standups, and retrospectives, ensuring timely delivery of high-quality software solutions.',
            'Ensured cross-browser compatibility and adherence to accessibility standards in all web applications.',
            'Collaborated closely with UI/UX designers to enhance user experience (UX), delivering seamless functionality and intuitive designs.',
            'Stayed up-to-date with advancements in front-end development technologies, incorporating relevant trends into projects.',
            'Tech stack: React JS, Tailwind CSS, Redux Toolkit, MUI, HTML5, CSS3'
        ]
    },
    {
        icon: ppl,
        date: 'April 2021 - March 2023',
        title: 'Software Developer @PeopleLink Unified Communications Pvt Ltd',
        subtitle: 'Hyderabad-Telangana, India',
        body: [
            'Joined the software frontend team, contributing actively with hands-on experience in React, JavaScript, HTML, CSS, Material UI, AWS, and MongoDB.',
            'Proficient in version control systems, including GitHub, Bitbucket, and AWS CodeCommit.',
            'Gained experience working with Meteor and React-based applications.',
            'Using ReactJS and Javascript as core technologies.',
            'Creation of reusable components.',
            'Integration with external APIs.'
        ]
    },
    {
        icon: ppl,
        date: 'September 2017 - March 2021',
        title: 'DevOps Engineer @PeopleLink Unified Communications Pvt Ltd',
        subtitle: 'Hyderabad-Telangana, India',
        body: [
            'Led and executed DevOps strategies across multiple onsite and offsite projects, streamlining software development and deployment processes.',
            'Utilized cloud platforms like AWS and DigitalOcean for efficient application hosting and scaling, optimizing infrastructure management and cost-effectiveness.',
            'Managed critical server configurations, including web server setups (Nginx), SSL certifications, and process management (PM2), ensuring high availability and security standards.',
            'Designed and maintained CI/CD pipelines using Jenkins, automating build, test, and deployment workflows to reduce release cycles and increase team productivity.',
            'Proficient in handling databases including MongoDB, DynamoDB, and MariaDB, as well as version control systems like GitHub, Bitbucket, and CodeCommit for efficient source control and collaboration.',
            'Provided user support and conducted training sessions, enabling end users to effectively navigate and utilize developed applications.'
        ]
    }
];

export default Experience;
