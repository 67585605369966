import React from 'react';
import { Container, Typography, Stack, Box } from '@mui/material';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './Experience.css';
import { config } from '../../config';
import ExpEn from './experienceListEN';
import { useSelector } from 'react-redux';
import eng from '../../Data/eng';

const Resume = () => {
    const { value } = useSelector((state) => state.lang);
    const data = value === 'en' ? eng : eng; // use for multiple lang
    const Experience = value === 'en' ? ExpEn : ExpEn; // use for multiple lang

    return (
        <Box
            component="section"
            id="Resume"
            sx={{
                color: config.TextColor,
                overflow: 'hidden',
                paddingY: '6.5vh'
            }}
        >
            <Container>
                <Stack justifyContent="start" alignItems="center" sx={{ marginBottom: '2rem' }}>
                    <Typography variant="subtitle1" sx={{ backgroundColor: config.PrimaryColor, paddingX: '10px', fontWeight: '500' }}>
                        {data.ExperienceTitle}
                    </Typography>
                    <Typography variant="h4" sx={{ fontWeight: '500' }}>
                        {data.ExperienceSubTitle}
                    </Typography>
                </Stack>
                <VerticalTimeline lineColor={config.PrimaryColor}>
                    {Experience.map((experience, index) => (
                        <VerticalTimelineElement
                            key={index}
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: config.White, color: '#000', border: '1px solid black' }}
                            contentArrowStyle={{ borderRight: '1px solid black' }}
                            date={experience.date}
                            icon={
                                <div className="icon-container">
                                    <img loading="lazy" className="icon-image" src={experience.icon} alt={`${experience.title} icon`} />
                                </div>
                            }
                        >
                            <Typography variant="h6">{experience.title}</Typography>
                            <Typography variant="subtitle1">{experience.subtitle}</Typography>
                            <Typography variant="body1">
                                {experience.body.map((item, idx) => (
                                    <ul key={idx}>
                                        <li>{item}</li>
                                    </ul>
                                ))}
                            </Typography>
                        </VerticalTimelineElement>
                    ))}
                </VerticalTimeline>
            </Container>
        </Box>
    );
};

export default Resume;
